import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Row, Col, Container, Card,CardBody, CardHeader,   } from "stories/layout";
import { Input } from "stories/forms";
import { Typography, Button, Link, IconButton, Modal, Spinner, BreadcrumbsDynamic, Popover } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"
import { round, roundScaleDecimals, thousandFormat } from "services/helpers"

import { httpGetPortfolioStocksList, httpGetPortfolioSummary, httpGetPortfolioSummaryExcel } from "services/portfolios"
import { httpGetStockDateBalance } from "services/transactions"
import { httpGetStockData } from "services/stocks"
import queryString from 'query-string'
import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"
import { useNotification } from "stories/components/Notification"

import { useAppContext } from "contexts/AppContext"



import PortfoliosSummaryTableLineChart from "views/app/PortfoliosSummaryChart/PortfoliosSummaryTableLineChart" 
import PortfolioSummaryPieChart from "views/app/PortfoliosSummaryChart/PortfolioSummaryPieChart" 

import PortfolioSelectStore from "components/Forms/PortfolioSelect/PortfolioSelectStore"
import PortfolioSelect from "components/Forms/PortfolioSelect/PortfolioSelect"
import SectorSelect from "components/Forms/SectorSelect/SectorSelect"
import TagSelect from "components/Forms/TagSelect/TagSelect"
import InvestmentTypeCateSelect from "components/Forms/InvestmentTypeCateSelect/InvestmentTypeCateSelect"
import CurrencyDateLogicSelect from "components/Forms/CurrencyDateLogicSelect/CurrencyDateLogicSelect"
import PIFUnlistedFundsSelect from "components/Forms/PIFUnlistedFundsSelect/PIFUnlistedFundsSelect"


import PortfolioEdit from "views/app/PortfolioEdit/PortfolioEdit"
import { set } from "date-fns";

const PortfolioSummary = ({match, history, location}) => {
  
  const { t } = useTranslation();
  const [summary, setSummary] = useState({
    market_value: 0.0,
    value_total_summary: 0.0,
    profit: 0.0,
    profit_percentage: 0.0,
  })
  const [stocks, setStocks] = useState()
  const [stocksData, setStocksData] = useState({})
  const [rows, setRows] = useState()
  const [portfolioSummaryLoading, setPortfolioSummaryLoading] = useState()
  const [selected_currency, setSelectedCurrency] = useState()
  const [selectedSector, setSelectedSector] = useState({sector_select: []});
  const [selectedTag, setSelectedTag] = useState({tag_select: []});
  const [checked, setChecked] = useState([])
  const [chartData, setChartData] = useState({})

  const { getSectorName, getSecurityTypeExtend  } = useAppContext()

  const account = useStoreState((state) => state.account?.account);


  const { notify } = useNotification();
  
  
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  const portfolios = useStoreState((state) => state.portfolio?.portfolios);
  const loading = useStoreState((state) => state.portfolio?.loading);
  const count = useStoreState((state) => state.portfolio?.count);
  const getPortfolios = useStoreActions((actions) => actions.portfolio.getPortfolios);
  const setPortfolio = useStoreActions((actions) => actions.portfolio.setPortfolio);
  const setPortfolioSummary = useStoreActions((actions) => actions.portfolioSummary.setPortfolioSummary);
  const portfolioSummary = useStoreState((state) => state.portfolioSummary?.portfolioSummary);
  const portfolioSummaryPortfolios = useStoreState((state) => state.portfolioSummary?.portfolio);
  const setPortfolioSummaryPortfolios = useStoreActions((actions) => actions.portfolioSummary.setPortfolio);
  
  
  const [selectedPortfolios, setSelectedPortfolios] = useState({
    selected_portfolios: [{id:portfolio?.id, value:portfolio?.name, name:portfolio?.name}]
  })

  
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    date: moment().format("YYYY-MM-DD"),
    selected_portfolios: [{id:portfolio?.id, value:portfolio?.name, name:portfolio?.name}],
    ordering: 'name',
    investmentType:[],
    currency_date_logic: {value: "latest", label: t("latest", "Tilannepäivä")},
    calculate_type: [{"value":"value_total","label":"acq to zero based on total value"}],
  })

  const [unlisted_funds_use_own_logic, setUnlistedFundsUseOwnLogic] = useState(account?.pif_unlisted_funds_use_own_logic)

  useEffect(() => {
    setUnlistedFundsUseOwnLogic(account?.pif_unlisted_funds_use_own_logic)
  }, [account])


  useEffect(() => {
    const selectedPortfolioIds = [];
    let currency = portfolio?.currency_label



    // filters.selected_portfolios.forEach(item => selectedPortfolioIds.push(item.id));
    selectedPortfolios?.selected_portfolios.forEach(item => selectedPortfolioIds.push(item.id));

    if (selectedPortfolios?.selected_portfolios && selectedPortfolios?.selected_portfolios.length == 1){
      //filter portfolios by selected portfolio
      const selectedPortfolio = portfolios.filter(item => item.id === selectedPortfolios?.selected_portfolios[0]?.id)
      currency = selectedPortfolio[0]?.currency_label
    }
    if (selectedPortfolios?.selected_portfolios && selectedPortfolios?.selected_portfolios.length > 1){
      currency = "EUR"
    }
    setSelectedCurrency(currency)
    
    setSelectedPortfolios(s => ({
      ...s,
      portfolio: selectedPortfolioIds?selectedPortfolioIds:[portfolio?.id],
      selected_currency: currency
    }))
  }, [filters.selected_portfolios, selectedPortfolios?.selected_portfolios])

  useEffect(() => {
    const selectedSectors = [];
    selectedSector?.sector_select?.forEach(item => selectedSectors.push(item.id));
    setSelectedSector(s => ({
      ...s,
      sector: selectedSectors
    }))
  }, [selectedSector?.sector_select])

  useEffect(() => {
    const selectedTags = [];
    selectedTag?.tag_select?.forEach(item => selectedTags.push(item.id));
    setSelectedTag(s => ({
      ...s,
      tag: selectedTags
    }))
  }, [selectedTag?.tag_select])

  // useEffect(() => {
  //   if (account && account?.pif_unlisted_funds_use_own_logic) {
  //     setUnlistedFundsUseOwnLogic(account?.pif_unlisted_funds_use_own_logic)
  //   }
  // }, [account])







  // Excel export BEGIN
  
  const [loadingExport, setLoadingExport] = useState(false)

  const handleExcelExport = (file_type="excel") => {
    // many portfolios selected
    const put_together = selectedPortfolios?.portfolio?.length > 1 ? true : false

    let unlisted_funds_dividends = false
    let unlisted_funds_others = false
    let zero_based_on_total_value = false
    let zero_based_on_quantity = false
    let use_original_calculation = false


    if (filters?.calculate_type && filters?.calculate_type.length > 0){
      //loop all selected calculate types
      filters?.calculate_type.map((item, index) => {
        if (item.value == 30){
          unlisted_funds_dividends = true
        }
        if (item.value == 50){
          unlisted_funds_others = true
        }
        if (item.value == "value_total"){
          zero_based_on_total_value = true
        }
        if (item.value == "quantity"){
          zero_based_on_quantity = true
        }
        if (item.value == "use_original_calculation"){
          use_original_calculation = true
        }
      })
    }

    const params = {
      ...filters,
      date_transaction__date__gte: filters?.date_transaction__date__gte ? moment(filters?.date_transaction__date__gte).format("YYYY-MM-DD") : undefined,
      date_transaction__date__lte: filters?.date_transaction__date__lte ? moment(filters?.date_transaction__date__lte).format("YYYY-MM-DD") : undefined,
      stock_market: filters?.stock_market ? filters?.stock_market : undefined,
      // ordering: '-id',
      client_timezone_offset: Intl.DateTimeFormat().resolvedOptions().timeZone,
      export_excel: true,
      portfolio: selectedPortfolios?.portfolio,
      sectors: selectedSector?.sector,
      tags: selectedTag?.tag,
      date_transaction: filters?.date,
      selected_currency: selected_currency,
      put_together: put_together,
      for_investime: true,
      currency_date_logic: filters?.currency_date_logic?.value,
      file_type: file_type,
      unlisted_funds_use_own_logic: unlisted_funds_use_own_logic,
      unlisted_funds_dividends: unlisted_funds_dividends,
      unlisted_funds_others: unlisted_funds_others,
      zero_based_on_total_value: zero_based_on_total_value,
      zero_based_on_quantity: zero_based_on_quantity,
      use_original_calculation: use_original_calculation,
      // investmentType: filters?.investmentType?.map(item => item.id),
      investmentType: filters?.investmentType?.filter(item => item.id !== "all").map(item => item.id)
    }

    let fileName = "investime_portfolio_summary_export.xlsx";

    if (file_type === "pdf"){
      fileName = "investime_portfolio_summary_export.pdf";
    }
    
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    
    setLoadingExport(true)

    if(file_type === "pdf"){
      notify({ title:t("Viedään PDF..."), icon: "fas fa-file-export"})
    }
    else{
      notify({ title:t("Viedään Exceliin..."), icon: "fas fa-file-export"})
    }


    httpGetPortfolioSummaryExcel(params).then(response => {
      let file = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

      if (file_type === "pdf"){
        file = new Blob([response.data], { type: 'application/pdf' });
      }

      let fileURL = window.URL.createObjectURL(file);
      a.href = fileURL;
      a.download = fileName;
      a.click();
      notify({ title:t("Lataus valmis"), icon: "fas fa-file-export"})
    })
    .finally(() => {
      setLoadingExport(false)
    })
  }

  const getSummaryData = (portfolio_param) => {
    if(selected_currency || portfolio_param){
      let unlisted_funds_dividends = false
      let unlisted_funds_others = false
      let zero_based_on_total_value = false
      let zero_based_on_quantity = false
      let use_original_calculation = false
  

  
      if (filters?.calculate_type && filters?.calculate_type.length > 0){
        //loop all selected calculate types
        filters?.calculate_type.map((item, index) => {
          if (item.value == 30){
            unlisted_funds_dividends = true
          }
          if (item.value == 50){
            unlisted_funds_others = true
          }
          if (item.value == "value_total"){
            zero_based_on_total_value = true
          }
          if (item.value == "quantity"){
            zero_based_on_quantity = true
          }
          if (item.value == "use_original_calculation"){
            use_original_calculation = true
          }

        })
      }


      const params = {
        // 'portfolio': portfolio?.id,
        'portfolio': portfolio_param? [portfolio_param.id]:selectedPortfolios?.portfolio, // many portfolios can be selected
        "put_together": true,
        'date_transaction': filters?.date,
        'selected_currency': portfolio_param? portfolio_param?.currency_label: selected_currency,
        'sectors': selectedSector?.sector,
        'tags': selectedTag?.tag,
        // 'investmentType': filters?.investmentType?.map(item => item.id),
        'investmentType': filters?.investmentType?.filter(item => item.id !== "all").map(item => item.id),
        'ordering_by': filters?.ordering,
        'for_investime': true,
        'currency_date_logic': filters?.currency_date_logic?.value,  // 'latest' or 'historical'
        'unlisted_funds_use_own_logic':  unlisted_funds_use_own_logic,
        'use_original_calculation': use_original_calculation,
        'unlisted_funds_dividends': unlisted_funds_dividends,
        'unlisted_funds_others': unlisted_funds_others,
        'zero_based_on_total_value': zero_based_on_total_value,
        'zero_based_on_quantity': zero_based_on_quantity,

      
        // 'ordering': 'related_model', // NOT WORK....
      }

      if ((selectedPortfolios?.selected_portfolios?.length > 0 && selectedPortfolios?.portfolio?.length > 0)|| portfolio_param) {
        setPortfolioSummaryLoading(true)

        httpGetPortfolioSummary(params).then(res => {
          if (res?.data) {
            //order by related_model.name
            // res.data.sort((a, b) => (a.related_model.name > b.related_model.name) ? 1 : -1)
            setRows(res?.data)

            let market_value = 0.0
            let value_total_summary = 0.0
            // loop all res.datas and calculate market_value
            res.data.map((item, index) => {
              if (item?.related_model_type == "10") {
              // market_value += item?.balance * item?.latest_stock_data?.ending_price
              market_value += item?.market_value
              }
              else {
                // market_value += item?.balance * item?.latest_stock_data?.price
                market_value += item?.market_value
              }
              value_total_summary += item?.value_total_summary
            })
            setSummary(s => ({
              ...s,
              market_value: market_value,
              value_total_summary: value_total_summary,
            }))
            if (portfolio_param && res?.data.length > 0){

              //do not do this if page are reloaded
              if (res?.data[0]?.portfolio == portfolio.id){

                setPortfolioSummary(res?.data)
                setPortfolioSummaryPortfolios({market_value: market_value, value_total_summary: value_total_summary})
              }
            }


            setPortfolioSummaryLoading(false)
          } 
          else  
          {
            setRows([])
            setPortfolioSummary([])
            setPortfolioSummaryPortfolios({market_value: 0.0, value_total_summary: 0.0})

            setPortfolioSummaryLoading(false)
            setSummary(s => ({  
              ...s,
              market_value: 0.0,
              value_total_summary: 0.0,
            }))

          } 
        }, (error) => {
          setRows([])
          setPortfolioSummary([])
          setPortfolioSummaryPortfolios({market_value: 0.0, value_total_summary: 0.0})

          setPortfolioSummaryLoading(false)
            setSummary(s => ({  
              ...s,
              market_value: 0.0,
              value_total_summary: 0.0,
            }))
        }
        )
      }
  }
  }


  useEffect(() => {
    
    if ((portfolioSummary?.length <= 0 && (portfolioSummaryLoading == undefined || !portfolioSummaryLoading )) || (portfolioSummary?.length > 0 && Number(PortfolioSummary[0]?.portfolio) !== Number(portfolio?.id ))) {
      // if (portfolioSummary?.length <= 0 ) {
      // console.log("portfolio.id", portfolio.id, portfolioSummary[0]?.portfolio)
      getSummaryData(portfolio)
    }
    // else{

    //   setSummary(s => ({  
    //     ...s,
    //     market_value: portfolioSummaryPortfolios?.market_value,
    //     value_total_summary: portfolioSummaryPortfolios?.value_total_summary,
    //   }))
    // }
    // console.log("portfolio.id", portfolio.id)
  }, [portfolio])


    

  useEffect(() => {
    setSelectedCurrency(portfolio?.currency_label)
    setSelectedPortfolios({selected_portfolios:[{id:portfolio?.id, value:portfolio?.name, name:portfolio?.name}]})

  }, [portfolio])

    
  
  
  const getCrumbLinks = () => {
    const crumbLinks = [
      {name: t("Etusivu"), url: "/dashboard/"},
      {name: t("portfolios_list_title","Salkut"), url: "/dashboard/portfolios"},

      {name: t("portfolios_summary_path","Salkun yhteenveto"), url: "/dashboard/portfolios/summary", optional: true},
      {name: portfolio.name, url: "/dashboard/portfolios/summary", optional: true},

    ]

    //if location state include crumbLinks return them
    if (location?.state && location?.state?.crumbLinks) {
      location.state.crumbLinks.push({name: portfolio?.name, url: location.pathname})
      return location.state.crumbLinks
    }
    //else return default links
    return crumbLinks
  }

  const handleChange = ({target}) => {
    setFilters({
      ...filters,
      [target.name]: target.value,
    })
  }
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const headers = [
    { label: t("portfolios_list_name", "Arvopaperi"), key: "name" , sortable: false,  sortingKey: "name" },
    { label: t("portfolios_list_balance", "Salkussa"), key: "balance"},
    { label: t("portfolios_list_balance_%", "Omistus%"), key: "size"},
    { label: t("portfolios_list_purchase_avg", "Hankinta-kurssi*"), key: "purchase_price_weighted_avg"},
    { label: t("portfolios_list_value_total_summary", "Hankinta-meno"), key: "value_total_summary"},
    { label: t("portfolios_list_category", "Laji"), key: "category"},
    { label: t("portfolios_list_secotor", "Toimiala"), key: "sector"},
    { label: t("portfolios_list_rate", "Markkina-kurssi"), key: "rate"},
    { label: t("portfolios_list_latest_stock_data_date", "Viimeisin kurssi-kirjaus"), key: "latest_stock_data_date"},

    { label: t("portfolios_list_value", "Markkina-arvo"), key: "market_value"},
    { label: `${t("portfolios_list_value_change", `Muutos`)} ${selected_currency}`, key: "market_value_change"},
    { label: t("portfolios_list_value_change_percent", `Muutos %`), key: "market_value_change_percent"},
    // { label: t("portfolios_list_expenses", "Hankintameno"), key: "expenses"},
    // { label: t("portfolios_list_profit", "Tuotto"), key: "profit"},
    // { label: t("portfolios_list_change", "Muutos"), key: "change"},
    // { label: t("portfolios_list_change_eur", "Muutos EUR"), key: "change_eur"},
    // { label: t("portfolios_list_other_profit_expenses", "Muut tuotot ja kulut"), key: "other_profit_expenses"},
    { label: t("portfolios_list_actions", " "), key: "actions"},
  ];

  const get_headers = useCallback(() => {

    // account?.show_relation_to_security && headers.splice(1, 0, { label: t("PrivateInvestmentFundRatesList_stock", "Kirjanpidoss"), key: "accounting_price_boolean"})

    const modifiedHeaders = [...headers]; // Create a copy of the headers array

    if (account?.show_relation_to_security) {
      modifiedHeaders.splice(7, 0, { label: t("portfolio_summary_accounting_price", "Kirjanpito-arvo"), key: "accounting_price" });
      modifiedHeaders.splice(10, 0, { label: t("portfolio_summary_accounting_market_value", "Kirjanpidon mark. arvo"), key: "accounting_market_value" });
    }
  
    return modifiedHeaders;
  }, [account, selected_currency])
  
  // useEffect(() => {
  //   let _rows = []
  //   let _summary = {
  //     market_value: 0.0
  //   }
  //   stocks && stocks.map((stock, index) => {
  //     const obj = {
  //       balance: 0,
  //       rate: 0,
  //       ...stocksData[stock.id]
  //     }
      
  //     const market_value = round(obj?.balance * obj?.rate, 2)
      
  //     // Row
  //     _rows.push({
  //       ...obj,
  //       market_value: market_value
  //     })
      
  //     // Summary
  //     _summary.market_value = round(_summary.market_value + market_value, 2)
  //   })
  //   setRows(_rows)
  //   setSummary(_summary)
  // }, [stocksData])
  
  const getTableRows = useCallback(() => {
    let rows_data = rows

    // if (portfolioSummary?.length > 0) {
    //   rows_data = portfolioSummary
    // }

    return rows_data && rows_data.length > 0 && rows_data.map((row, index) => {
      let rate = row?.latest_stock_data?.price
      let accounting_rate = row?.latest_accounting_stock_data?.price
      let latest_stock_data_date = row?.latest_stock_data?.date
      if (row.latest_stock_data?.stock_date){
        latest_stock_data_date = row.latest_stock_data?.stock_date
      }

      if (row?.related_model_type == "10") {
        rate = row?.latest_stock_data?.ending_price
      }
      if (row?.related_model_type != "30") {
        accounting_rate = rate
      }
      let market_value = row?.market_value
      
      let market_value_change = market_value + row?.value_total_summary

      let market_value_change_percent = thousandFormat(roundScaleDecimals(market_value_change / (row.value_total_summary * -1)* 100, 2))
      //if market_value_change_percent is NaN
      if (isNaN(market_value_change_percent)) {
        market_value_change_percent = roundScaleDecimals(1-1, 2) // 0.00
      }

      const size = row?.related_model?.size;

      const calculatePercentage = (balance, size) => {
        const percentage = (balance / size) * 100;
        return percentage > 0.0001 ? roundScaleDecimals(percentage, 4) : percentage;
      };
      let related_model_type_cell = (
        <span className="text-start text-dark m-0" style={{maxWidth:"10px"}}>
          <div id={`issue-${index}`}>{t("Alle 0,0001")}%</div>
          <Popover
            placement="top"
            target={`issue-${index}`}
          >       
            <Typography className="text-dark mb-1" variant="medium">
              {calculatePercentage(row?.balance, Number(size))} 
            </Typography>  
          </Popover>
        </span> 
      )

      const result = size !== null && size !== undefined && size !== 0
        ? calculatePercentage(row?.balance, Number(size)) > 0.0001 ? `${calculatePercentage(row?.balance, Number(size))}%` : related_model_type_cell
        : t("Ei saatavilla");


        const balance = (account?.portfolio_summary_quantity_decimals_logic == true && ["10", "private_stock", "listed_stock"].includes(row?.related_model?.type))? {
          content: `${thousandFormat(roundScaleDecimals(row?.balance, 0))}`,
          className: 'text-right'
        }: account?.portfolio_summary_quantity_decimals_logic == true ?{
          content: `${thousandFormat(roundScaleDecimals(row?.balance, 4))}`,
          className: 'text-right'
        }: 
        {
          content: `${thousandFormat(roundScaleDecimals(row?.balance, 2))}`,
          className: 'text-right'
        }

        
  
      
      return {
        ...row,
        id: row?.related_model?.id,
        name: row?.related_model?.name,
        sector:  t(`sector_select_${row?.related_model?.sector_code}`,getSectorName(row?.related_model?.sector_code)),
        category: t(row?.related_model?.type, getSecurityTypeExtend(row?.related_model_type, row?.related_model?.type)?.value),
        size: result,
        // balance: `${roundScaleDecimals(row?.balance, 2)} ${t("kpl")}`,
        // balance: {'
        //   content: `${thousandFormat(roundScaleDecimals(row?.balance, 2))}`,
        //   className: 'text-right'
        // },'
        balance: balance,
        rate: rate?`${roundScaleDecimals(rate, 2)}`:<span className="text-danger">{t("Ei saatavilla")}</span>,
        // purchase_price_weighted_avg: `${roundScaleDecimals(row?.purchase_price_weighted_avg, 2)} ${portfolio?.currency_label}`,
        purchase_price_weighted_avg: {
          content: `${thousandFormat(roundScaleDecimals(row?.purchase_price_weighted_avg * -1, 2))}`,
          className: 'text-right'
        },
        accounting_price: accounting_rate?`${roundScaleDecimals(accounting_rate, 2)}`:<span className="text-danger">{t("Ei saatavilla")}</span>,

        latest_stock_data_date: latest_stock_data_date ?moment(latest_stock_data_date).format("DD-MM-YYYY"): <span className="text-danger">{t("Ei saatavilla")}</span>  ,
        // value_total_summary: `${roundScaleDecimals(row?.value_total_summary,2)}`,
        value_total_summary: {
          content: `${thousandFormat(roundScaleDecimals(row?.value_total_summary, 2))}`,
          className: 'text-right'
        },
        // market_value: `${roundScaleDecimals(market_value, 2)} ${portfolio?.currency_label}`,
        market_value: {
          content: `${thousandFormat(roundScaleDecimals(market_value, 2))} `,
          className: 'text-right'
        },
        accounting_market_value: {
          content: `${thousandFormat(roundScaleDecimals(row?.accounting_market_value, 2))} `,
          className: 'text-right'
        },

        // market_value_change: `${roundScaleDecimals(market_value_change, 2)} ${portfolio?.currency_label}`,
        market_value_change: {
          content: `${thousandFormat(roundScaleDecimals(market_value_change, 2))}`,
          className: 'text-right'
        },
        // market_value_change_percent: `${roundScaleDecimals(market_value_change / (row.value_total_summary * -1)* 100, 2)} %`,
        market_value_change_percent: {
          content: `${market_value_change_percent} %`,
          className: 'text-right'
        },

      }
    })
  }, [rows, portfolioSummary])
  
  // Modal: New/Edit portfolio
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    getPortfolios()
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }

  const setFieldValue = (name, value) => {
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }

  const handlePortfolioChange = (name, value) => {
    setSelectedPortfolios(s => ({
      ...s,
      [name]: value
    }))
  }

  const handlePortfolioChange1= ({target}) => {
    if (target.value !== "10" ) {
      setFilters(s => ({
        ...s,
        [target?.name]: target.value,
        related_model_type: undefined,
        stock_market: undefined,
        related_model_id: undefined
      }))

    }
    else {
    setFilters(s => ({
      ...s,
      [target?.name]: target.value,
      related_model_type: undefined,
      related_model_id: undefined
    }))
  }
  }

  const handleSectorChange = (name, value) => {
    setSelectedSector(s => ({
      ...s,
      [name]: value
    }))
  }
  const handleTagChange = (name, value) => {
    setSelectedTag(s => ({
      ...s,
      [name]: value
    }))
  }

  const getCurrencySelect = useCallback(() => {
    let default_currencys = ["EUR","USD","SEK"]

    //Add only selected currency to default currencys
    default_currencys = [...default_currencys, portfolio?.currency_label]

    //ADD ALL PORTFOLIO CURRENCYS TO DEFAULT CURRENCYS
    // //map all portfolios and get all currencys
    // const all_currencys = portfolios.map((portfolio) => {
    //   return portfolio?.currency_label
    // })
    // //remove duplicates
    // const unique_currencys = [...new Set(all_currencys)];
    // //add unique currencys to default currencys
    // default_currencys = [...default_currencys, ...unique_currencys]

    return (
      <CurrencySelect 
      name='currency'
      value={selected_currency}
      onChange={(event) => setSelectedCurrency(event.target.value)}
      label={t("Valuutta")} 
      disabled={false}
      useOnlyList = {default_currencys}
      // useOnlyList = {filters?.selected_currency}
      />
    )
  }, [selected_currency, portfolio])

  const getPortfolioSelect = useCallback(() => {
    return (
      <PortfolioSelect 
      name="selected_portfolios"
      label={t("Salkku")}
      setFieldValue={handlePortfolioChange}
      multiple={true}
      placeholder={t("select_portfolio", "Valitse salkku")}
      onChange={handlePortfolioChange}
      value= {selectedPortfolios?.selected_portfolios}
    />
    )
  }, [portfolio, selectedPortfolios?.selected_portfolios, selectedPortfolios])

  const getSectorSelect = useCallback(() => {
    return (
      <SectorSelect
        name="sector_select"
        label={`${t("sector_title", "Toimiala")}` }
        multiple={true}
        onChange={handleSectorChange}
        value={selectedSector?.sector_select}
        setFieldValue={handleSectorChange}
      />
    )
  }, [selectedSector])

  const getTagSelect = useCallback(() => {
    return (
      <TagSelect
        name="tag_select"
        label={`${t("tag_title", "Kategoria")}` }
        multiple={true}
        onChange={handleTagChange}
        value={selectedTag?.tag_select}
        setFieldValue={handleTagChange}
      />
    )
  }, [selectedTag])

  function getFirstDayXMonthsAgo(months) {
    const date = new Date();
    
    // Vähennä 6 kuukautta nykyisestä kuukaudesta
    date.setMonth(date.getMonth() - months);
  
    // Aseta päivä kuukauden ensimmäiseksi päiväksi
    date.setDate(1);
  
    return date.toISOString().split('T')[0];
  }
  

  const getTable = useCallback(() => {
    return(
      <>
      <TableAuto
      showRowNumber={false}
      showId={false}
      checkboxes={false}
      headers={get_headers()}
      headerColor={"light"}
      rows={getTableRows()}
      loading={portfolioSummaryLoading}
      pagination={true}
      paginationPrevious={paginationPrevious}
      paginationNext={paginationNext}
      paginationOffset={filters?.offset}
      paginationLimit={filters?.limit}
      paginationCount={count}
      setFilters={setFilters}
      setChecked={setChecked}
      
      name="portfolio_summary_table"
      />
      </>
    )
    },[portfolioSummaryLoading])



  const getInvestmentTypeSelect = useCallback(() => {
    return <InvestmentTypeCateSelect name="investmentType" onChange={setFieldValue} showSubsOptions={true} showAllOption={false}  multiple={true} setFieldValue={setFieldValue} value={filters.investmentType} label={t("investmentType_select_label",'Arvopaperi laji')} />
    }, [filters?.investmentType,])

  const getCurrencyDateLogicSelect = useCallback(() => {
    return <CurrencyDateLogicSelect  name="currency_date_logic" onChange={setFieldValue} setFieldValue={setFieldValue} value={filters?.currency_date_logic} />
    }, [filters?.currency_date_logic,])
  
  return (
    <>
   <Row className="d-flex  flex-wrap  ">
    <Col>
     <PageHeader title={t("Salkut")} />
      <BreadcrumbsDynamic links={getCrumbLinks()} />
    </Col>
    <Col md="6" xs="12" className="d-flex flex-column justify-content-end  mr-xs-auto align-self-end mb-3 " style={{minWidth: "50px"}}>
      <div className="ml-auto">
      <Button color="secondary" size="sm" disabled={loadingExport} onClick={() => handleExcelExport("excel")}>
              <i className="fas fa-file-excel"></i>&nbsp;
              {t("excel_export","Vie (Excel)")}
            </Button>

            <Button color="secondary" size="sm" disabled={loadingExport} onClick={() => handleExcelExport("pdf")}>
              <i className="fas fa-file-pdf"></i>&nbsp;
              {t("pdf_export","Vie (PDF)")}
            </Button>
      </div>
    </Col>
    </Row>
      
      <PageContainer>

        {/* {JSON.stringify(portfolioSummary)} */}
        {/* <PortfoliosSummaryTableLineChart portfolio={portfolio.id} date__gte={getFirstDayXMonthsAgo(11)} setChartData={setChartData} /> */}


  
      
        <div className="d-flex justify-content-start mb-2 d-flex flex-wrap">
          {/* <div className="p-2">
            {match.params.id}
            <PortfolioSelectStore label={t("portfolios_list_portfolio_filter", "Salkku")}/>

          </div> */}
          <div className="d-flex justify-content-start align-items-start py-2 pr-2 mr-3" style={{minWidth: '150px', maxWidth: '320px'}}>
                  {/* <PortfolioSelect 
                  name="selected_portfolios"
                  label={t("Salkku")}
                  setFieldValue={setFieldValue}
                  multiple={true}
                  placeholder={t("select_portfolio", "Valitse salkku")}
                  onChange={handlePortfolioChange}
                  value= {filters.selected_portfolios}
                /> */}
                {getPortfolioSelect()}
          </div>

          <div className="d-flex justify-content-start align-items-start py-2 pr-2 mr-3" style={{minWidth: '150px', maxWidth: '320px'}}>
            {getInvestmentTypeSelect()}
          </div>

          <div className="p-2">
            {/* {JSON.stringify(selectedSector)} */}
            {/* {JSON.stringify(selectedPortfolios.portfolio)} */}
            {getSectorSelect()}
          </div>

          <div className="p-2">
            {/* {JSON.stringify(selectedSector)} */}
            {/* {JSON.stringify(selectedPortfolios.portfolio)} */}
            {getTagSelect()}
          </div>

          <div className="p-2">
            {getCurrencyDateLogicSelect()}
          </div>
          {/* <div className="p-2">
          <PIFUnlistedFundsSelect  name="calc" onChange={setFieldValue} setFieldValue={setFieldValue}  isMulti={true} value={filters?.calc} />
          </div> */}

          {
            account?.pif_unlisted_funds_use_own_logic &&          
            <div className="p-2" style={{minWidth: '250px',  maxWidth: '250px'}}>
              <PIFUnlistedFundsSelect name="calculate_type" label={t("portfolio_summary_calculate_type", "Noteeraamaton rahasto")} isMulti={true} placeholder={t('Huomioi myös')} onChange={handleChange} value={filters?.calculate_type} setFilters={setFilters}/>
            </div>
          }
          <div className="p-2">
            {getCurrencySelect()}
          </div>


          <div className="p-2">
            <Input label={t("portfolios_list_date_filter", "Tilannepäivä")} name="date" type="date" value={filters?.date} onChange={handleChange} />
          </div>

          <div className="d-flex justify-content-start align-items-start mt-3 py-2 pl-2 pr-2  ml-0 mr-3 ml-xxl-auto mr-xxl-0">
            <Button name="profit_search_button" className="mt-2" color="secondary" onClick={() => getSummaryData()}>{t("Hae")}</Button>
          </div>
          
        </div>
        {/* {JSON.stringify(filters)} */}
        {getTable()}
       
        
        <Row>
          <Col>{/*t("Yhteenveto")*/}</Col>
        </Row>
        
        <div className="bg-success">

        </div>
        
        <Modal
          title={t("Salkku")}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
        >
          <PortfolioEdit
            objectId={modalEditId}
            handleResolve={modalEditResolve}
            handleReject={modalEditReject}
          />
        </Modal>
        {(account?.show_frontpage_portfolio_summary && false ) &&
        <Row className="mb-3">
          <Col xs={12} lg={6}> 
            <Card className={`shadow-sm-strong  h-100  `} >
            <CardHeader className={`d-flex justify-content-between align-items-center text-bol `} >
                {/* <i className="fas fa-briefcase text-primary  fa-2x mr-3" /> */}
                  <Typography>
                  <span variant="h4" className="text-bold mr-1 ">
                  {t("front_page_selected_portfolio","Markkina-arvo")}: 
                  </span>

                    </Typography> 
                {/* </h3> */}
                <span variant="h4" className="text-bold ml-auto">
     
                </span>
            </CardHeader>
              <CardBody>
                <PortfoliosSummaryTableLineChart portfolio={portfolio.id} date__gte={getFirstDayXMonthsAgo(5)} setChartData={setChartData} type={'market_value'} />
              </CardBody>
            </Card>
          </Col>

          <Col xs={12} lg={6} className="mt-sm-3 mt-lg-0">
          <Card className={`shadow-sm-strong  h-100  `} >
          <CardHeader className={`d-flex justify-content-between align-items-start text-bol `} >
                {/* <i className="fas fa-briefcase text-primary  fa-2x " /> */}
                  <Typography>
                  <span variant="h4" className="text-bold mr-1 ">
                  {t("portfolio_summary_pie_allocation","Allokaatio")}: 
                  </span>

                    </Typography> 
 
            </CardHeader>

              <CardBody className="my-auto">
                <div className="my-auto">

                  <PortfolioSummaryPieChart data={rows ? rows: []}/>
                </div>
                {/* {JSON.stringify(rows)} */}
              </CardBody>
            </Card>
          </Col>
        </Row>
        }
      </PageContainer>
      <PageContainer className="bg-lighter">
      <Typography  variant="display5" >
      <Row >
        <Col className="col-sm-12 col-md-6 col-xl-4">
          <Row>
            <Col className="col-lg-5 col-xl-4">{t("portfolios_summary_market_value","Markkina-arvo")}</Col>
            <Col className={summary?.market_value >= 0?"text-success":"text-warning"}>{thousandFormat(roundScaleDecimals(summary?.market_value, 2))} </Col>
          </Row>
          <Row>
            <Col className="col-lg-5 col-xl-4">{t("portfolios_summary_purchase_costs","Hankintameno")}</Col>
            <Col className={summary?.value_total_summary >= 0?"text-success":"text-warning"}>
              {thousandFormat(roundScaleDecimals(summary?.value_total_summary, 2))} 
            </Col>
          </Row>
        </Col>
        <Col className="col-sm-12 col-md-6 col-xl-4">
          <Row>
            <Col className="col-lg-5 col-xl-4">{t("portfolios_summary_profit","Tuotto")} {selected_currency}</Col>
            <Col className={summary?.market_value + summary?.value_total_summary >= 0?"text-success ":"text-warning"}>
              {thousandFormat(roundScaleDecimals(summary?.market_value + summary?.value_total_summary, 2))} 
            </Col>
          </Row>
          <Row>
            <Col className="col-lg-5 col-xl-4">{t("portfolios_summary_profit", "Tuotto")} %</Col>
            <Col className={(summary?.market_value + summary?.value_total_summary) / (summary?.value_total_summary * -1) < 0 || (summary?.market_value + summary?.value_total_summary) / (summary?.value_total_summary * -1) == null?"text-warning":"text-success"}>
              {thousandFormat(roundScaleDecimals(summary?.market_value == 0 && summary?.value_total_summary == 0? 0:(summary?.market_value + summary?.value_total_summary) / (summary?.value_total_summary * -1) * 100, 2))} %
            </Col>
          </Row>
        </Col>
      </Row>
      </Typography>


      </PageContainer>
      <Typography  variant="small" className="mt-5 text-gray">
        * {t("portfolios_summary_info_text_1","Hankintakurssi on laskettu painoitetulla keskihinnalla")}
      </Typography>

    </>
  );
}

export default PortfolioSummary;
