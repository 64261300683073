import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { httpGetPortfolioSummaryTable } from 'services/portfolios';
import { tr } from 'date-fns/locale';
import { round, roundScaleDecimals } from "services/helpers"  

// Register required Chart.js components
ChartJS.register(CategoryScale, LinearScale, ArcElement, Tooltip, Legend);

const PieChart = ({ data, label_count=10 }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    // Fetch data for the chart 
    const t_data = transformData(data); // Transform your data to fit the chart
    setChartData(t_data);
  }, [data]);

  // Example function to transform API data into chart-friendly format
  const transformData = (data) => {
    // Example: Extract labels and values for the pie chart
    const threshold = 5; // Combine data below this value

    let labels = [];
    let values = [];
    let total_quantity = 0;

    if (data.length > 0) {
      // Calculate total quantity for percentage calculation
      total_quantity = data.reduce((sum, item) => sum + Number(item?.market_value), 0);
      console.log("total_quantity", total_quantity);

      // Create a deep copy of the data array and sort it
      const dataCopy = JSON.parse(JSON.stringify(data));
      const largestOne = dataCopy.sort((a, b) => b?.market_value - a?.market_value).slice(0, label_count - 1);

      console.log("largestOne", largestOne);

      // Calculate the sum of the largest values
      const sumOfLargest = largestOne.reduce((sum, item) => sum + Number(item?.market_value), 0);
      console.log("sumOfLargest", sumOfLargest, total_quantity);

      // Add "Others" category
      const filteredData = [...largestOne];
      if (largestOne.length < data.length) {
        filteredData.push({ market_value: total_quantity - sumOfLargest, related_model: { name: 'Others' } });
      }

      values = filteredData.map((item) => {
        console.log(item?.market_value, total_quantity);
        console.log(roundScaleDecimals(Number(item?.market_value) / total_quantity * 100, 2));
        return roundScaleDecimals(Number(item?.market_value) / total_quantity * 100, 2);
      });

      labels = filteredData.map((item) => item?.related_model?.name); // Replace 'category' with your actual key
    }

    return {
      labels: labels,
      datasets: [
        {
          label: 'Portfolio Distribution',
          data: values,
          backgroundColor: [
            'rgba(253, 205, 63, 0.8)',
            'rgba(22, 22, 64, 0.8)',
            'rgba(255, 111, 97, 0.8)',
            'rgba(107, 91, 149, 0.8)',
            'rgba(136, 176, 75, 0.8)',
            'rgba(247, 202, 201, 0.8)',
            'rgba(146, 168, 209, 0.8)',
            'rgba(149, 82, 81, 0.8)',
            'rgba(181, 101, 167, 0.8)',
            'rgba(255, 160, 122, 0.8)'
          ],
          borderColor: [
            'rgba(253, 205, 63, 1)',
            'rgba(22, 22, 64, 1)',
            'rgba(255, 111, 97, 1)',
            'rgba(107, 91, 149, 1)',
            'rgba(136, 176, 75, 1)',
            'rgba(247, 202, 201, 1)',
            'rgba(146, 168, 209, 1)',
            'rgba(149, 82, 81, 1)',
            'rgba(181, 101, 167, 1)',
            'rgba(255, 160, 122, 1)'
          ],
          borderWidth: 1,
        },
      ],
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true, // Maintain aspect ratio
    aspectRatio: 10 / 6, // Set the aspect ratio to 4:3
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxWidth: 20, // Size of the color box next to the label
          padding: 15, // Padding between legend items
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.raw || 0;
            return `${label}:  ${value}%`;
          },
        },
      },
    },
    // Custom rendering of text inside the pie slices
    elements: {
      arc: {
        borderWidth: 1,
      },
    },
  };

  return (
    <div>
      {chartData && <Pie data={chartData} options={options} />}
    </div>
  );
};

export default PieChart;